import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { AxiosResponse } from 'axios';
import PlaceType from '../../models/PlaceType';
import BasePage from '../../models/BasePage';
import to from 'await-to-js';
import { siteService, bankHolidayService, calendarService, pricesService, financeAccountService } from '@/main';
import RecreapiEvent from '../../models/RecreapiEvent';
import { Watch } from 'vue-property-decorator';
import { VueContext } from 'vue-context';
import { EventCategory } from '../../models/EventCategory';
import moment from 'moment/min/moment.min.js';
import Feature from '../../models/Feature';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';
import { $router } from '@/router';
import FinanceAccount from '@/models/Finance/FinanceAccount';

@Component({
    components: {
        VueContext,
    },
    mixins: [validationMixin],
    validations: {
        placeType: {
            name: { required },
            capacity: { required },
            propertyType: { required },
            defaultDayPrice: { required },
        },
    },
})
export default class AccomodationTypePage extends BasePage implements IVuelidate<any> {
    public placeType: PlaceType = new PlaceType();
    public placeTypeKey: string = '';
    public loadingFinanceAccounts = false;
    public financeAccounts: FinanceAccount[] = [];

    public newFeature: Feature = { name: '' } as Feature;
    public $refs!: {
        newFeatureModal: BModal;
        priceModal: BModal;
        priceRangeModal: BModal;
        occupationRangeModal: BModal;
    };

    @Watch('editMode')
    public async watchEditMode() {
        this.loadingFinanceAccounts = true;
        const [err, response] = await to(financeAccountService.getAccounts(this.siteId));
        this.loadingFinanceAccounts = false;

        if (err) {
            this.showError('Mislukt om grootboekrekeningen op te halen.');
        }

        this.financeAccounts = response.data.map((account: FinanceAccount) => {
            return {
                accountNumber: account.accountNumber,
                financeAccountId: account.financeAccountId,
                name: account.name,
            };
        });
    }

    public async created() {
        await this.initSite();
        await this.initBase();
        this.placeTypeKey = this.$route.params.typeKey;
        this.placeType = await this.getPlaceTypeByKey(this.placeTypeKey);

        if (this.placeTypeKey === 'aanmaken') {
            this.edit();
        }

        this.isLoading = false;
    }

    public getNotSelectedFeatures() {
        return this.features.filter((feature: Feature) => {
            const exists = this.placeType.features.find((f: Feature) => {
                return f.featureId === feature.featureId;
            });
            return !exists;
        });
    }

    public onFeatureAdd(feature: Feature) {
        this.placeType.features.push({
            name: feature.name,
            featureId: feature.featureId,
            amount: 1,
        } as Feature);
    }

    public removeFeature(index: number = 0) {
        this.placeType.features.splice(index, 1);
    }

    public async getPlaceTypeByKey(key): Promise<PlaceType> {
        const placeType = {} as PlaceType;
        if (key !== 'aanmaken') {
            const [err, response] = await to(siteService.getPlaceType(this.siteKey, key));
            if (err) {
                this.clearAndShowError('Mislukt om type accommodatie op te halen', err);
                return {} as PlaceType;
            }
            Object.assign(placeType, response.data);
        }
        return new PlaceType(placeType);
    }

    public async save() {
        if (!this.validateObject('placeType')) {
            return;
        }

        this.showPending('Accommodatie type opslaan...');
        const [err, response] = await to(siteService.createOrUpdatePlaceType(this.siteKey, this.placeType));
        if (err) {
            return this.clearAndShowError('Mislukt om type accommodatie op te slaan', err);
        }

        const newPlaceType = response.data;
        if (this.placeType.placeTypeKey === 'aanmaken' || this.placeType.placeTypeKey !== newPlaceType.placeTypeKey) {
            $router.push({
                name: 'acco-type',
                params: { siteId: this.siteId.toString(), siteKey: this.siteKey, typeKey: newPlaceType.placeTypeKey },
            });
        }

        this.clearAndShowSuccess('Accommodatie type succesvol opgeslagen');
        this.editMode = false;
    }

    public openAddFacilityPopup(item) {
        this.$refs.newFeatureModal.show();
        return item;
    }
}
