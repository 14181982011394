import Component from 'vue-class-component';
import { siteService, bankHolidayService, calendarService, dateHelper, homeOwnerService, affiliatePlacesService, loginService, loginHelper } from '@/main';
import { BModal } from 'bootstrap-vue';
import to from 'await-to-js';
import { AxiosResponse } from 'axios';
import PlaceType from '../../models/PlaceType';
import Place from '../../models/Place';
import Feature from '../../models/Feature';
import BasePage from '../../models/BasePage';
import RecreapiEvent from '../../models/RecreapiEvent';
import moment from 'moment/min/moment.min.js';
import { EventCategory } from '../../models/EventCategory';
import { VueContext } from 'vue-context';
import { Watch } from 'vue-property-decorator';
import HomeOwner from '../../models/HomeOwner';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';
import { $router } from '@/router';
import { AffiliatePlaceStatus } from '@/models/Affiliate/AffiliatePlaceStatus';

@Component({
    components: {
        VueContext,
    },
    mixins: [validationMixin],
    validations: {
        place: {
            placeType: { required },
            name: { required },
            capacity: { required },
        },
    },
})
export default class Accomodation extends BasePage implements IVuelidate<any> {
    public placeKey: string = '';
    public place: Place = {} as Place;
    public events: RecreapiEvent[] = [];
    public calendar: any = { days: [] };
    public requestedPlaceTypeId: number = null;
    public selectedDates: any = null;
    public selectedPlotType = { id: 'Default', name: 'Niet gedefinieerd' };

    public basePrice: any = '0';
    public editedOccupation = [];
    public newOccupation: any = { isAvailable: false, desscription: '' };
    public viewMenu: boolean = false;
    public top: string = '0px';
    public left: string = '0px';
    public newFeature: Feature = { name: '' } as Feature;

    public plotTypes = [
        { id: 'Default', name: 'Niet gedefinieerd' },
        { id: 'Ownership', name: 'Koop' },
        { id: 'Rental', name: 'Huur' },
    ];

    public homeOwners: HomeOwner[] = [] as HomeOwner[];

    public themeStyles: any = {};
    public attrs: any = [
        {
            isInline: true,
            key: 'today',
            dates: new Date(),
        },
    ];

    public $refs!: {
        featureModal: BModal;
        menu: any;
        priceModal: BModal;
        occupationModal: BModal;
        priceRangeModal: BModal;
        occupationRangeModal: BModal;
        confirmChangePlaceTypeModal: BModal;
    };

    public selectedDatesSettings = {
        plugins: ['remove_button'],
        delimiter: ',',
        persist: false,
        options: [],
    };

    @Watch('selectedDates', { deep: true, immediate: true })
    public OnDateChange(val, oldVal) {
        if (val) {
            this.selectedDatesSettings.options = [val.start, val.end];
        }
    }

    public async created() {
        const self = this;
        await this.initSite();
        await this.initBase();
        this.placeKey = this.$route.params.placeKey;
        this.place = await this.getPlace(this.placeKey);
        await this.getHomeOwners();

        if (this.placeKey === 'aanmaken') {
            this.edit();
        } else {
            this.loadCalendarEvents();
        }

        this.isLoading = false;
        if (this.place.placeTypeId) {
            this.setPlaceType();
        }

        if (this.place.propertyType.propertyTypeId) {
            this.setPropertyType();
        }

        if (this.place.plotType) {
            this.selectedPlotType = this.plotTypes.find((x) => x.id === this.place.plotType);
        }
    }

    public async loadCalendarEvents() {
        const calendarResponse = await this.getCalendarData();
        this.calendar = calendarResponse;
        this.loadCalendar();
    }

    public loadCalendar() {
        this.attrs = [
            {
                isInline: true,
                key: 'today',
                dates: new Date(),
            },
        ];

        const prices = this.calendar.days.reduce((t: any, day: any) => {
            if (day.entries.find((entry) => entry.eventType.eventCategory === EventCategory.NationalHoliday)) {
                const event = day.entries.find((entry) => entry.eventType.eventCategory === EventCategory.NationalHoliday);
                t.push({
                    dates: moment(day.day, 'YYYY-MM-DD').toDate(),
                    popover: {
                        label: event.eventType.name + ': ' + event.name,
                    },
                    bar: {
                        backgroundColor: event.eventType.eventTypeId === 1 ? 'green' : 'blue', // Red bar
                    },
                });
            }

            if (day.entries.find((entry) => entry.eventType.eventCategory === EventCategory.Occupation)) {
                const event = day.entries.find((entry) => entry.eventType.eventCategory === EventCategory.Occupation);
                t.push({
                    dates: moment(day.day, 'YYYY-MM-DD').toDate(),
                    popover: {
                        label: 'Niet beschikbaar: ' + event.name,
                    },
                    bar: {
                        backgroundColor: 'red',
                    },
                });
            }

            return t;
        }, []);

        this.attrs = this.attrs.concat(prices);
    }

    public getPrice(attr: any, day: any) {
        if (attr.length > 0) {
            const attrWithCustomData = attr.find((attribute) => {
                return attribute.customData;
            });
            return attrWithCustomData ? attrWithCustomData.customData.name : 'N/A';
        }

        return 'N/A';
    }

    public async getBankHolidays() {
        const [err, eventResponse] = await to<AxiosResponse<RecreapiEvent[]>>(bankHolidayService.getBankHolidays());
        if (err) {
            this.showFailedResponse('Mislukt om feestdagen op te halen', err);
            return [];
        }
        return eventResponse.data;
    }

    public async getCalendarData() {
        const [err, calendar] = await to<AxiosResponse>(calendarService.getPlaceCalendar(this.placeKey));
        if (err) {
            this.showFailedResponse('Mislukt om agenda op te halen', err);
            return [];
        }

        return (this.calendar = calendar.data);
    }

    public getPlaceType(id) {
        return this.placeTypes.find((placeType: PlaceType) => {
            return placeType.placeTypeId === id;
        });
    }

    public async savePlace() {
        if (!this.validateObject('place')) {
            return;
        }

        this.showPending('Accommodatie opslaan...');
        if (this.place.placeTypeId !== null) {
            const placeType = this.getPlaceType(this.place.placeTypeId);
            this.place.placeTypeKey = placeType.placeTypeKey;
        }
        this.place.propertyType = this.getPropertyType(this.place.propertyType.propertyTypeId);
        this.place.plotType = this.selectedPlotType.id;

        const [err, response] = await to(siteService.createOrUpdatePlace(this.siteKey, this.place));
        if (err) {
            return this.clearAndShowError('Mislukt om de accommodatie op te slaan', err);
        }

        this.editMode = false;
        this.clearAndShowSuccess('Accommodatie succesvol opgeslagen.');

        if (this.placeKey === 'aanmaken' || this.placeKey !== this.place.placeId.toString()) {
            $router.push({
                name: 'acco',
                params: { placeKey: this.place.placeId.toString() },
            });
        }
    }

    public getNotSelectedFeatures() {
        return this.features.filter((feature: Feature) => {
            let existsInType;
            const exists = this.place.features.find((f: Feature) => {
                return f.featureId === feature.featureId;
            });

            if (this.place.placeType && this.place.placeTypeFeatures) {
                existsInType = this.place.placeTypeFeatures.find((f: Feature) => {
                    return f.featureId === feature.featureId;
                });
            }
            return !exists && !existsInType;
        });
    }

    public onFeatureAdd(feature: Feature) {
        this.place.features.push({
            name: feature.name,
            featureId: feature.featureId,
            amount: 1,
        });
    }

    public removeFeature(index) {
        if (this.place.features.length > index) {
            this.place.features.splice(index, 1);
        }
    }

    public async onPlaceTypeSelected(placeType: PlaceType) {
        const self = this;
        // When place is new, copy all data from placeType
        if (!this.place.placeId || this.place.placeId <= 0) {
            const [err, response] = await to<AxiosResponse<PlaceType>>(siteService.getPlaceType(this.siteKey, placeType.placeTypeKey));
            if (err) {
                this.showFailedResponse('Mislukt om accommodatietype op te halen', err);
            }

            const placeTypeData = response.data as PlaceType;
            const placeTypeFeatures = [...placeTypeData.features];
            placeTypeData.features = [];

            Object.assign(this.place, placeTypeData);
            this.place.placeTypeFeatures = placeTypeFeatures;
            this.place.placeTypeId = this.place.placeType.placeTypeId;
        } else {
            const originalPlaceType = this.placeTypes.find((type) => {
                return type.placeTypeId === self.place.placeTypeId;
            });
            this.place.placeTypeId = originalPlaceType.placeTypeId;
            this.requestedPlaceTypeId = placeType.placeTypeId;
            this.$refs.confirmChangePlaceTypeModal.show();
        }
    }

    public confirmEditPlaceType() {
        // this.place.placeType = this.placeTypes.find((x) => x.placeTypeId === this.requestedPlaceTypeId);
        this.place.placeTypeId = this.requestedPlaceTypeId;
        this.cancleEditPlaceType();
    }

    public cancleEditPlaceType() {
        this.requestedPlaceTypeId = null;
        this.$refs.confirmChangePlaceTypeModal.hide();
    }

    public openAddFacilityPopup(item) {
        return item;
    }

    public onClick(text, data) {
        const clickedDate = dateHelper.format(data.day.date);

        if (!this.selectedDates) {
            this.selectedDates = {
                start: clickedDate,
                end: clickedDate,
            };
        } else if (this.selectedDates && data.day.date) {
            const dates = dateHelper.getDatesBetweenRange(this.selectedDates.start, this.selectedDates.end);
            if (dates.indexOf(clickedDate) < 0) {
                this.selectedDates = {
                    start: clickedDate,
                    end: clickedDate,
                };
            }
        }

        switch (text) {
            case 'occupation':
                this.editOccupationInRange();
                break;
            default:
                break;
        }
    }

    public async editOccupationInRange() {
        this.$refs.occupationRangeModal.show();

        const dates = dateHelper.getDatesBetweenRange(this.selectedDates.start, this.selectedDates.end);
        const events = this.calendar.days.filter((event) => {
            if (dates.indexOf(dateHelper.format(event.day)) < 0) {
                return false;
            }

            const occupations = event.entries.filter((entry) => {
                return entry.eventType.eventCategory === 'Occupation';
            });

            return occupations.length;
        });

        this.newOccupation.isAvailable = !events.length;
    }

    public async upsertOccupations() {
        let occupation = {};
        const placeId = this.place.placeId;
        const start = this.selectedDates.start;
        const end = this.selectedDates.end;
        const startDate = dateHelper.format(start);
        const endDate = dateHelper.format(end);

        this.showPending('Beschikbaarheid wordt opgeslagen...');

        if (!this.newOccupation.isAvailable) {
            const self = this;
            occupation = {
                description: self.newOccupation.description,
                startDate,
                endDate,
                occupationType: 1,
            };
            const [err, response] = await to(calendarService.saveOccupation(occupation, placeId, this.siteKey, startDate, endDate));
            if (err) {
                return this.clearAndShowError('Beschikbaarheid kon niet opgeslagen worden.', err);
            }
        } else {
            const [err, response] = await to(calendarService.removeOccupation(placeId, this.siteKey, startDate, endDate));
            if (err) {
                return this.clearAndShowError('Beschikbaarheid kon niet opgeslagen worden.', err);
            }
        }

        this.clearAndShowSuccess('Beschikbaarheid is succesvol opgeslagen.');
        this.$refs.occupationRangeModal.hide();
        this.selectedDates = null;
        this.loadCalendarEvents();
    }

    public book() {
        const start = moment(this.selectedDates.start).format('YYYY-MM-DD');
        const end = moment(this.selectedDates.end).format('YYYY-MM-DD');

        $router.push({
            name: 'create-booking',
            query: {
                placeTypeIds: JSON.stringify([this.place.placeTypeId]),
                site: this.site.siteId.toString(),
                siteOwner: loginHelper.getSiteOwner().toString(),
                fromDate: start,
                toDate: end,
                placeId: this.place.placeId.toString(),
            },
        });
    }

    public getNameHomeOwnerName(homeOwner: HomeOwner) {
        return `${homeOwner.firstName}${homeOwner.insertion ? ' ' + homeOwner.insertion : ''} ${homeOwner.lastName}`;
    }

    public getHomeOwner(id: number) {
        const self = this;
        if (id) {
            return this.getNameHomeOwnerName(this.homeOwners.find((homeOwner) => homeOwner.homeOwnerId === id));
        } else {
            return 'Het park is eigenaar van dit object';
        }
    }

    public get hasBookingInRange() {
        const startDate = moment(this.selectedDates.start);
        const endDate = moment(this.selectedDates.end).add('d', 1);
        let hasBookingInRange = false;

        this.calendar.days.forEach((x) => {
            if (
                x.entries.find((entry) => entry.eventType.eventCategory === EventCategory.Occupation && entry.name === 'Booked') &&
                !hasBookingInRange
            ) {
                hasBookingInRange = moment(x.day).isBetween(startDate.toString('YYYY-MM-DD'), endDate.toString('YYYY-MM-DD'));
                if (hasBookingInRange) {
                    return;
                }
            }
        });

        return hasBookingInRange;
    }

    private setPlaceType() {
        this.place.placeType = this.placeTypes.find((type) => {
            return type.placeTypeId === this.place.placeTypeId;
        });
    }

    private setPropertyType() {
        this.place.propertyType = this.propertyTypes.find((type) => {
            return type.propertyTypeId === this.place.propertyType.propertyTypeId;
        });
    }

    private async getHomeOwners() {
        const [err, response] = await to(homeOwnerService.getHomeOwners());
        if (err) {
            return this.showError('Mislukt om eigenaren op te halen');
        }

        return (this.homeOwners = response.data);
    }
}
